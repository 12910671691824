<template>
    <div class="app-container">
        <div class="app-top-btn">
            <el-select
                v-model="listQuery.type"
                clearable
                size="medium"
                @change="resetPageNum"
                style="width: 120px; margin-left: 10px"
            >
                <el-option
                    v-for="item in peopleType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="listQuery.applyBankStatus"
                filterable
                size="medium"
                placeholder="开户状态"
                @change="resetPageNum"
                style="width: 120px; margin-left: 10px"
            >
                <el-option
                    v-for="item in applyBankStatusList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="listQuery.agreeSign"
                filterable
                size="medium"
                placeholder="签约状态"
                @change="resetPageNum"
                style="width: 120px; margin-left: 10px"
            >
                <el-option
                    v-for="item in agreeSignList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-date-picker
                type="daterange"
                size="medium"
                style="width: 280px; margin-left: 10px"
                range-separator="-"
                start-placeholder="注册开始日期"
                end-placeholder="结束日期"
                format="yyyyMMdd"
                value-format="yyyyMMdd"
                :picker-options="searchDatePickerOptions"
                v-model="searchDatePicker"
                @change="searchDate"
            >
            </el-date-picker>

            <el-input
                placeholder="姓名或手机号码或身份证号码"
                size="medium"
                v-model="listQuery.keyWords"
                @keyup.enter.native="searchKeyWords"
                prefix-icon="el-icon-search"
                style="width: 235px; margin: 0 0 0 10px"
            >
            </el-input>
            <div style="display: flex; align-items: center; margin-top: 10px">
                <el-upload
                    style="margin-right: 10px"
                    action="#"
                    accept=".xls,.xlsx"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="(file) => uploadPerson(file)"
                >
                    <el-button type="warning" size="mini"> 导入人员 </el-button>
                </el-upload>
                <el-button type="danger" size="mini" @click="peopleInfoExplore">
                    人员导出
                </el-button>
                <el-button
                    style="margin: 0 0 0 12px"
                    type="info"
                    size="mini"
                    @click="downloadTemp"
                >
                    下载Excel模板
                </el-button>
                <el-button
                    style="margin: 0 0 0 12px"
                    type="primary"
                    size="mini"
                    @click="myFormAdd"
                >
                    新增
                </el-button>
            </div>
        </div>
        <el-table :data="list" border fit highlight-current-row>
            <el-table-column align="center" label="姓名" min-width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="手机号码" min-width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.phone }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="身份证号码" min-width="180">
                <template slot-scope="scope">
                    <span>{{ scope.row.cardno }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="单位名称" min-width="250">
                <template slot-scope="scope">
                    <span>{{ scope.row.departmentName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="开卡状态" min-width="120">
                <template slot-scope="scope">
                    <el-tag
                        type="danger"
                        v-if="scope.row.applyBankStatus === 0"
                    >
                        未开卡
                    </el-tag>
                    <el-tag
                        type="success"
                        v-else-if="scope.row.applyBankStatus === 1"
                    >
                        已开卡
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="充值状态" width="90">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.rechargeStatus === 0"
                        size="mini"
                        type="danger"
                        @click="
                            switchRechargeStatus('确定启用吗', scope.row, 1)
                        "
                    >
                        未启用
                    </el-button>
                    <el-button
                        v-else-if="scope.row.rechargeStatus === 1"
                        size="mini"
                        type="success"
                        @click="
                            switchRechargeStatus('确定禁用吗', scope.row, 0)
                        "
                    >
                        已启用
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="聚富通状态" min-width="120">
                <template slot-scope="scope">
                    <el-tag
                        type="success"
                        v-if="scope.row.externalAgreeSign === 1"
                    >
                        已签约
                    </el-tag>
                    <el-tag type="danger" v-else> 未签约 </el-tag>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                label="登记时间"
                width="165"
                prop="regTime"
            ></el-table-column>
            <el-table-column
                align="center"
                label="最后登录时间"
                width="165"
                prop="lastLogTime"
            ></el-table-column>

            <el-table-column align="center" min-width="80" label="状态">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        size="mini"
                        v-if="scope.row.status == 1"
                        @click="switchStatus('确定禁用吗', scope.row.id, 0)"
                    >
                        已启用
                    </el-button>
                    <el-button
                        type="danger"
                        size="mini"
                        v-if="scope.row.status == 0"
                        @click="switchStatus('确定启用吗', scope.row.id, 1)"
                    >
                        未启用
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                fixed="right"
                min-width="300"
                label="操作"
            >
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="myFormEdit(scope.row)"
                    >
                        编辑
                    </el-button>

                    <el-button
                        type="warning"
                        size="mini"
                        v-if="
                            scope.row.agreeSign === 1 ||
                            scope.row.authSign === 1
                        "
                        @click="userResetAuth(scope.row)"
                    >
                        重置授权
                    </el-button>
                    <!-- <el-button
                        type="danger"
                        size="mini"
                        @click="passwordDialog(scope.row)"
                    >
                        重置支付密码
                    </el-button> -->
                    <el-button
                        type="danger"
                        size="mini"
                        @click="myFormDeparture('确定离职吗', scope.row)"
                    >
                        离职
                    </el-button>
                    <!-- <el-dropdown size="medium" style="margin-left: 10px">
                        <el-button type="danger" size="mini">
                            操作
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-button
                                    type="danger"
                                    size="mini"
                                    @click="
                                        myFormDeparture('确定离职吗', scope.row)
                                    "
                                >
                                    离职
                                </el-button>
                            </el-dropdown-item>
                            <el-dropdown-item style="margin: 5px 0">
                                <el-button
                                    type="danger"
                                    size="mini"
                                    @click="passwordDialog(scope.row)"
                                >
                                    重置支付密码
                                </el-button>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total,prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>

        <!--重置支付密码-->
        <el-dialog
            title="重置支付密码"
            :visible.sync="passwordDialogShow"
            width="400px"
        >
            <el-form
                :model="passwordFields"
                :rules="passwordRules"
                ref="passwordForm"
                label-width="70px"
            >
                <el-form-item label="新密码" prop="payPassword">
                    <el-input v-model="passwordFields.payPassword"></el-input>
                </el-form-item>
                <el-form-item style="margin: 0px">
                    <el-button type="primary" @click="passwordSubmit()">
                        提交
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--新增/编辑-->
        <el-dialog
            :title="myFormTitle"
            :visible.sync="myFormDialog"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            width="400px"
        >
            <el-form
                :model="myFormFields"
                :rules="myFormRules"
                ref="myForm"
                label-width="90px"
            >
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="myFormFields.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="myFormFields.phone"></el-input>
                </el-form-item>
                <el-form-item label="身份证" prop="cardno">
                    <el-input v-model="myFormFields.cardno"></el-input>
                </el-form-item>
                <el-form-item label="餐补标准" prop="foodStandard">
                    <el-input v-model="myFormFields.foodStandard"></el-input>
                </el-form-item>
                <el-form-item style="margin: 0">
                    <el-button type="primary" @click="myFormSubmit">
                        提交
                    </el-button>
                    <el-button @click="myFormReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--导入失败信息-->
        <el-dialog
            title="导入人员失败信息"
            :visible.sync="failPerson.show"
            width="1200px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-table :data="failPerson.data" border fit highlight-current-row>
                <el-table-column
                    align="center"
                    label="身份证"
                    prop="cardno"
                    min-width="200"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="姓名"
                    prop="name"
                    min-width="100"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="手机号"
                    prop="phone"
                    min-width="180"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="失败原因"
                    prop="reason"
                    min-width="280"
                ></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { Export2Excel } from '@/utils/excel'
export default {
    data() {
        return {
            activeName: 'first',
            list: [],
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                regStartTime: '', //查询开始日期
                regEndTime: '', //查询截止日期
                keyWords: '', //查询关键字
                // applyBankStatus: '',
                authSign: '',
                status: '',
                departId: '',
                type: '',
            },
            peopleType: [
                {
                    id: '',
                    name: '全部用户',
                },
                {
                    id: 0,
                    name: '社会用户',
                },
                {
                    id: 1,
                    name: '单位用户',
                },
            ],
            agreeSignList: [
                {
                    id: '',
                    name: '全部',
                },
                {
                    id: 0,
                    name: '未签约',
                },
                {
                    id: 1,
                    name: '已签约',
                },
            ],
            applyBankStatusList: [
                {
                    id: '',
                    name: '全部',
                },
                {
                    id: 0,
                    name: '未开卡',
                },
                {
                    id: 1,
                    name: '已开卡',
                },
            ],
            searchDatePicker: [], //查询日期范围选择
            searchDatePickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                },
            },
            passwordDialogShow: false,
            passwordFields: {
                id: '',
                payPassword: '',
            },
            passwordRules: {
                payPassword: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
            },
            myFormType: true, //true为新增，false为编辑
            myFormTitle: '新增',
            myFormDialog: false,
            myFormFields: {},
            myFormRules: {
                userName: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                cardNo: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                departmentId: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                    {
                        type: 'number',
                        message: '手机号格式不正确',
                        trigger: 'blur',
                        transform(value) {
                            if (!/^1\d{10}$/.test(value)) {
                                return false
                            } else {
                                return Number(value)
                            }
                        },
                    },
                ],
            },
            releaseUserCardInfoDialog: {
                title: '用户开卡信息解绑',
                show: false,
                width: '500px',
                ref: 'releaseUserCardInfoForm',
                fields: {
                    userId: 0,
                    password: '',
                },
                rules: {
                    password: [
                        {
                            required: true,
                            message: '必填',
                            trigger: 'blur',
                        },
                    ],
                },
            },
            moneyTransDialog: {
                title: '用户旧平台余额转移到新平台',
                show: false,
                width: '500px',
                ref: 'moneyTransForm',
                fields: {
                    userId: 0,
                    password: '',
                },
                rules: {
                    password: [
                        {
                            required: true,
                            message: '必填',
                            trigger: 'blur',
                        },
                    ],
                },
            },
            rechargeListDialog: {
                title: '个人充值列表',
                show: false,
                cardNo: '',
                list: [],
                listTotal: 0,
                userName: '',
                totalAmount: 0,
                allRecords: [],
                rechargeListQuery: {
                    pageNum: 1,
                    pageSize: 10,
                    regStartTime: '', //查询开始日期
                    regEndTime: '', //查询截止日期
                    userId: '',
                },
            },
            failPerson: {
                data: [],
                show: false,
            },
        }
    },
    created() {
        //获取列表
        this.getList()
    },
    methods: {
        //启用禁用 - 充值状态
        switchRechargeStatus(title, item, status) {
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api
                    .user_update({
                        id: item.id,
                        rechargeStatus: status,
                    })
                    .then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },

        //下载模板
        downloadTemp() {
            window.open(
                'https://ghlxapi.dynews.zj.cn/manager/file/template/单位-单位管理-人员管理-导入模板.xlsx'
            )
        },

        //分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        getRecords(page) {
            if (page != this.rechargeListDialog.rechargeListQuery.pageNum) {
                this.rechargeListDialog.rechargeListQuery.pageNum = page
                this.selectRecords()
            }
        },
        //查询日期
        searchDate() {
            this.listQuery.pageNum = 1
            this.listQuery.regStartTime = this.searchDatePicker[0]
            this.listQuery.regEndTime = this.searchDatePicker[1]
            this.getList()
        },
        //查询关键字
        searchKeyWords() {
            this.listQuery.pageNum = 1
            this.getList()
        },
        //获取列表
        getList() {
            this.$api.user_page(this.listQuery).then((res) => {
                console.log(res)
                this.listTotal = res.pageInfo.total
                this.list = res.data
            })
        },
        //启用禁用
        switchStatus(title, id, status) {
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api
                    .user_update({
                        id: id,
                        status: status,
                    })
                    .then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },
        //重置支付密码 - 弹窗
        passwordDialog(item) {
            //定义参数
            this.passwordFields = {
                id: item.id,
                payPassword: '',
            }
            //显示弹窗
            this.passwordDialogShow = true
        },
        //重置支付密码 - 表单提交
        passwordSubmit() {
            this.$refs['passwordForm'].validate((valid) => {
                if (valid) {
                    this.$api.user_resetPWD(this.passwordFields).then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.passwordDialogShow = false
                    })
                }
            })
        },
        resetPageNum() {
            this.listQuery.pageNum = 1
            this.getList()
        },
        //人员导入
        uploadPerson(file) {
            const loading = this.$loading({
                lock: true,
                text: '人员导入中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            const data = new FormData()
            // data.append('departId', id)
            data.append('file', file.raw)
            this.$api.user_importExcel(data).then((res) => {
                console.log('upload', res)
                if (res && res.length) {
                    this.$message({
                        duration: 1000,
                        type: 'warning',
                        message: '上传成功，部分人员导入失败！',
                    })
                    this.failPerson.data = res
                    this.failPerson.show = true
                } else {
                    this.$message({
                        duration: 1000,
                        type: 'success',
                        message: '上传成功',
                    })
                }
                loading.close()
                this.getList()
            })
        },
        //签约
        agreeSign(item) {
            this.$confirm('确定要签约吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .get('/userlist/agreementSign', {
                        params: {
                            userId: item.id,
                        },
                    })
                    .then((res) => {
                        if (res === null) {
                            this.$message({
                                duration: 1000,
                                type: 'fail',
                                message: '签约失败',
                            })
                        } else {
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: '签约成功',
                            })
                            this.getList()
                        }
                    })
            })
        },
        // 批量签约
        agreeSignAll() {
            this.list.forEach((item) => {
                this.$http
                    .get('/userlist/agreementSign', {
                        params: {
                            userId: item.id,
                        },
                    })
                    .then((res) => {
                        if (res === null) {
                            this.$message({
                                duration: 1000,
                                type: 'fail',
                                message: item.name + '签约失败',
                            })
                        } else {
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: item.name + '签约成功',
                            })
                        }
                    })
            })
        },
        //新增/编辑 - 编辑
        myFormAdd() {
            //定义参数
            this.myFormFields = {}
            //显示弹窗
            this.myFormTitle = '新增'
            this.myFormType = true //true为新增，false为编辑
            this.myFormDialog = true
        },
        //新增/编辑 - 编辑
        myFormEdit(item) {
            //定义参数
            this.myFormFields = {
                id: item.id,
                name: item.name,
                phone: item.phone,
                cardno: item.cardno,
                departmentId: item.departmentId,
                foodStandard: item.foodStandard,
            }
            //显示弹窗
            this.myFormTitle = '编辑'
            this.myFormType = false //true为新增，false为编辑
            this.myFormDialog = true
        },
        //新增/编辑 - 表单重置
        myFormReset() {
            this.$refs['myForm'].resetFields()
        },
        //新增/编辑 - 表单提交
        myFormSubmit() {
            this.$refs['myForm'].validate((valid) => {
                if (valid) {
                    this.myFormFields.foodStandard =
                        this.myFormFields.foodStandard * 1
                    if (this.myFormType) {
                        this.$api.user_save(this.myFormFields).then((res) => {
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: res,
                            })
                            this.myFormDialog = false
                            this.getList()
                        })
                    } else
                        this.$api.user_update(this.myFormFields).then((res) => {
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: res,
                            })
                            this.myFormDialog = false
                            this.getList()
                        })
                }
            })
        },
        // 用户开卡信息解绑
        releaseUserCardInfo(item) {
            this.releaseUserCardInfoDialog.fields.userId = item.id
            this.releaseUserCardInfoDialog.title =
                '用户【' + item.name + '】开卡信息解绑'
            this.releaseUserCardInfoDialog.show = true
        },
        // 用户开卡信息解绑 - 提交
        releaseUserCardInfoSubmit() {
            this.$refs[this.releaseUserCardInfoDialog.ref].validate((valid) => {
                if (valid) {
                    this.$http
                        .post(
                            '/userlist/releaseUserCardInfo',
                            this.releaseUserCardInfoDialog.fields
                        )
                        .then((res) => {
                            console.log(res)
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: '操作成功',
                            })
                            this.releaseUserCardInfoDialog.show = false
                            this.getList()
                        })
                }
            })
        },
        // 余额转移到新平台
        moneyTrans(item) {
            this.moneyTransDialog.fields.userId = item.id
            this.moneyTransDialog.title =
                '用户【' + item.name + '】余额转移到新平台'
            this.moneyTransDialog.show = true
        },
        // 余额转移到新平台 - 提交
        moneyTransSubmit() {
            this.$refs[this.moneyTransDialog.ref].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在转移，请耐心等待...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    this.$http
                        .post(
                            '/userlist/moneyTrans',
                            this.moneyTransDialog.fields
                        )
                        .then((res) => {
                            console.log(res)
                            loading.close()
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: '操作成功',
                            })
                            this.moneyTransDialog.show = false
                            this.getList()
                        })
                }
            })
        },
        //离职
        myFormDeparture(title, item) {
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api.user_dimission({ id: item.id }).then((res) => {
                    this.$message({
                        duration: 1000,
                        type: 'success',
                        message: res,
                    })
                    this.getList()
                })
            })
        },
        userResetAuth(item) {
            this.$confirm('授权状态和签约状态全部重置', '重置授权', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api
                    .user_resetAuth({
                        id: item.id,
                    })
                    .then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },
        selectRecords() {
            let url = '/userlist/getDeptRechargeRecords'
            if (this.activeName == 'second') {
                url = '/userlist/getPersonalRechargeRecords'
            }
            this.$http
                .get(url, {
                    params: this.rechargeListDialog.rechargeListQuery,
                })
                .then((res) => {
                    this.rechargeListDialog.listTotal = res.pageInfo.total
                    this.rechargeListDialog.list = res.data
                })
        },

        queryRechargeList(row) {
            this.rechargeListDialog.rechargeListQuery.userId = row.id
            this.rechargeListDialog.userName = row.name
            this.rechargeListDialog.cardNo = row.cardNo
            console.log(row.cardNo)
            this.selectRecords()
            this.getAllRecords()
            this.rechargeListDialog.show = true
        },
        getAllRecords() {
            let url = '/userlist/getDeptAllRecords'
            if (this.activeName == 'second') {
                url = '/userlist/getPersonalAllRecords'
            }
            this.$http
                .get(url, {
                    params: {
                        userId: this.rechargeListDialog.rechargeListQuery
                            .userId,
                    },
                })
                .then((res) => {
                    this.rechargeListDialog.allRecords = res.records
                    this.rechargeListDialog.totalAmount = res.totalAmount
                    console.log(this.rechargeListDialog.allRecords)
                })
        },
        // 导出excel
        excelOut() {
            if (this.rechargeListDialog.allRecords.length > 0) {
                this.rechargeListDialog.userName + '个人充值记录',
                    ['用户姓名', '充值金额', '充值时间'],
                    ['userName', 'amount', 'rechargeTime'],
                    this.rechargeListDialog.allRecords
            } else {
                this.$message({
                    duration: 1000,
                    message: '没有可导出的数据',
                    type: 'error',
                })
                return false
            }
        },
        handleClick(tab, event) {
            console.log(tab, event)
            this.selectRecords()
            this.getAllRecords()
        },
        //导出excel
        peopleInfoExplore() {
            const loading = this.$loading({
                lock: true,
                text: '数据下载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            this.$api
                .user_list(this.listQuery)
                .then((res) => {
                    // res.unshift({
                    //     agreeSign: '',
                    //     cardno: '',
                    //     foodStandard: '',
                    //     id: '',
                    //     name: '',
                    //     phone: '',
                    // })
                    if (res.length > 0) {
                        // for (let index = 0; index < res.length; index++) {
                        //     if (res[index].agreeSign == 1) {
                        //         res[index].agreeSign = '已签约'
                        //     } else if (res[index].agreeSign == 0) {
                        //         res[index].agreeSign = '未签约'
                        //     }
                        // }
                        Export2Excel(
                            '人员数据导出',
                            ['用户名', '身份证', '联系电话', '餐补标注'],
                            ['name', 'cardno', 'phone', 'foodStandard'],
                            res
                        )
                        loading.close()
                    } else {
                        this.$message({
                            duration: 1000,
                            message: '没有可导出的数据',
                            type: 'error',
                        })
                        loading.close()
                        return false
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
        jumptoquery() {
            this.$router.push({
                path: '/bank/bankquery',
                query: { cardNo: this.rechargeListDialog.cardNo },
            })
        },
    },
}
</script>
